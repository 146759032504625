export default class CustomerFilter {
    constructor() {
        this.filterWrapper = document.querySelector('.customer-filter-wrapper');
        this.filterPackage = document.getElementById('customer-filter-package');
        this.filterStatus = document.getElementById('customer-filter-status');
        this.filterFree = document.getElementById('customer-filter-free');
        this.filterSearch = document.getElementById('customer-filter-search');
        this.customerRows = document.querySelectorAll('.customer-row');
        this.noResultsRow = document.getElementById('customer-filter-no-results');

        this.package = null;
        this.status = null;
        this.free = null;
        this.search = null;

        window.addEventListener('load', () => {
            this.init();
        });
    }

    init() {
        if (this.filterWrapper) {
            this.filterPackage.addEventListener('change', (e) => {
                this.package = (e.target.value) ? e.target.value : null;
                this.filterCustomer();
            });

            this.filterStatus.addEventListener('change', (e) => {
                this.status = (e.target.value) ? e.target.value : null;
                this.filterCustomer();
            });

            this.filterFree.addEventListener('change', (e) => {
                this.free = (e.target.value) ? e.target.value : null;
                this.filterCustomer();
            });

            this.filterSearch.addEventListener('change', (e) => {
                this.search = (e.target.value) ? e.target.value : null;
                this.filterCustomer();
            });
        }
    }

    filterCustomer() {
        this.customerRows.forEach((v, k) => {
            if (this.package === null &&
                this.status === null &&
                this.free === null &&
                this.search === null) {
                v.classList.remove('d-none');
                return;
            }

            v.classList.remove('d-none');

            if (this.package !== null) {
                if (!v.dataset.package.includes(this.package)) {
                    v.classList.add('d-none');
                }
            }

            if (this.status !== null) {
                if (v.classList.contains('d-none'))
                    return;
                if (v.dataset.status !== this.status) {
                    v.classList.add('d-none');
                }
            }

            if (this.free !== null) {
                if (v.classList.contains('d-none'))
                    return;
                if (v.dataset.free !== this.free) {
                    v.classList.add('d-none');
                }
            }

            if (this.search !== null) {
                if (v.classList.contains('d-none'))
                    return;
                if (!((v.dataset.company.includes(this.search)) || (v.dataset.owner.includes(this.search)))) {
                    v.classList.add('d-none');
                }
            }

            let results = document.querySelectorAll('.customer-row:not(.d-none)');
            if (results.length === 0) {
                this.noResultsRow.classList.remove('d-none');
            } else {
                this.noResultsRow.classList.add('d-none');
            }
        });
    }
}
